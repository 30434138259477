import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl`}</strong>{` -- easy-to-use high-speed tools for encryption and signing`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`The `}<strong parentName="p">{`step crypto nacl`}</strong>{` command group is a thin CLI wrapper around the NaCl
(pronounced "salt") cryptography library. NaCl's goal is to provide all of the
core operations needed to build higher-level cryptographic tools.`}</p>
    <p>{`Perhaps its biggest advantage is simplicity. NaCl was designed to be easy to
use and hard to misuse. Typical cryptographic libraries force you to specify
choices for cryptographic primitives and constructions (e.g., sign this
message with 4096-bit RSA using PKCS#1 v2.0 with SHA-256). But most people are
not cryptographers. These choices become foot guns. By contrast, NaCl allows
you to simply say "sign this message". NaCl ships with a preselected choice --
a state-of-the-art signature system suitable for most applications -- and it
has a side mechanism through which a cryptographer can easily override the
choice of signature system.`}</p>
    <p>{`There are language bindings and pure implementations of NaCl for all major
languages. For internal use cases where compatibility with open standards like
JWT are not an issue, NaCl should be your default choice for cryptographic
needs.`}</p>
    <p>{`For more information on NaCl visit `}<a parentName="p" {...{
        "href": "https://nacl.cr.yp.to"
      }}>{`https://nacl.cr.yp.to`}</a></p>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "auth/"
              }}>{`auth`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`authenticate a message using a secret key`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "box/"
              }}>{`box`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`authenticate and encrypt small messages using public-key cryptography`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "secretbox/"
              }}>{`secretbox`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`encrypt and authenticate small messages using secret-key cryptography`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "sign/"
              }}>{`sign`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sign small messages using public-key cryptography`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      